import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { UserService } from '../user/user.service';
import { ChatService } from '@core/firebase/chat/chat.service';
import { ChatRoomMapper } from '@core/models/map-struct/chat-room.mapper';
import { Pagination } from '@app/@core/models/datatypes/pagination.interface';
import { ChatRoom, ChatRoomType, ChatState, CreatableChatRoom } from '@core/models/interfaces/chat-room.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MediaService } from '@core/api/media/media.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ChatRoomService {
  private readonly entityName = 'api/chat-rooms';
  private readonly entityRef = 'api::chat-room.chat-room';

  private rawChatRooms$: BehaviorSubject<{ data: ChatRoom[] }>;
  private sortedChatRooms$: Observable<ChatRoom[]>;
  private chatStates: { [key: string]: Observable<ChatState> };

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly userService: UserService,
    private readonly chatService: ChatService,
    private readonly chatRoomMapper: ChatRoomMapper,
    private translateService :TranslateService,
  ) {}

  public async getChatRoomById(id: string | number, invalidateCache = false): Promise<ChatRoom> {
    return this.apiService.getWithCaching(`${this.entityName}/${id}`, {
      populate: [
        'picture',
        'owner',
        'participants',
        'participants.profilePicture',
      ],
    }, invalidateCache).then(res => this.chatRoomMapper.toEntity(res.data));
  }

  public async getChatRooms(invalidateCache = false, search: string = undefined, page = 1, pageSize = 25): Promise<{
    data: ChatRoom[];
    meta: Pagination;
  }> {
    const me = await this.userService.getMe();

    const filters = {
      participants: me.id,
    } as any;

    if (search !== undefined) {
      filters.$or = [
        { title: { $containsi: search } },
      ];
    }

    return this.apiService.getWithCaching(
      this.entityName,
      {
        populate: [
          'picture',
          'owner',
          'participants',
          'participants.profilePicture',
        ],
        filters,
        pagination: {
          page,
          pageSize,
        },
      },
    ).then((res) => ({
      data: res.data.map(
        (property) => this.chatRoomMapper.toEntity(property),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async updateChatRoom(chatRoom: Partial<ChatRoom> & { id: number }) {
    const response = await this.apiService.put(`${this.entityName}/${chatRoom.id}`, {
      data: {
        ...chatRoom,
      },
    });

    await this.refreshChatRooms();
    return response;
  }

  public async setChatRoomStatus(chatRoomId: number, open: boolean) {
    return this.updateChatRoom({ id: chatRoomId, open });
  }

  public async createChatRoom(chatRoom: CreatableChatRoom) {
    const me = await this.userService.getMe();

    // Convert User objects to IDs, ensure current user is participant, and make array unique
    const participants = new Set([
      ...chatRoom?.participants.map((user) => typeof user === 'number' ? user : user.id),
      me.id,
    ]);

    if (participants.size < 2) {
      throw new Error(this.translateService.instant('errors.message.chat.chat-room-service-create-chat-room'));

    }

    const response = await this.apiService.post(`${this.entityName}`, {
      data: {
        ...chatRoom,
        // Estimate chat room type if not specified
        type: chatRoom?.type ?? chatRoom.participants.length <= 2 ? 'dm' : 'group',
        // Convert User objects to IDs or use current user ID if unspecified owner
        owner: (
          chatRoom?.owner && (
            typeof chatRoom.owner === 'number' ? chatRoom.owner : chatRoom.owner.id
          )
        ) || me.id,
        participants: Array.from(participants),
        // All new chat rooms must be open
        open: true,
      },
    });

    // Asynchronously refresh rooms, but don't wait for it
    this.refreshChatRooms();
    return response;
  }

  public async startChatRoom(type: ChatRoomType, participants: number[], owner?: number): Promise<number | undefined> {
    const createdChatRoom = await this.createChatRoom({
      type,
      participants,
      owner,
    });

    const createdChatRoomId = createdChatRoom?.data?.id;

    if (createdChatRoom == null) {
      throw new Error(this.translateService.instant('errors.message.chat.chat-room-service-start-chat-room'));
    }

    return createdChatRoomId;
  }

  public async getContentType() {
    return this.apiService.getWithCaching(`api/content-type-builder/content-types/${this.entityRef}`);
  }

  public async addParticipant(chatRoom: ChatRoom, userId: number) {
    return this.apiService.put(`${this.entityName}/${chatRoom.id}`, {
      data: {
        participants: [
          ...chatRoom.participants.map(m => m.id),
          userId,
        ],
      },
    });
  }

  public async updatePicture(id: number, file: string | File | Blob) {
    const response = await this.mediaService.uploadFiles(id, this.entityRef, 'picture', file);

    // Asynchronously refresh rooms, but don't wait for it
    this.refreshChatRooms().then();
    return response;
  }

  public async refreshChatRooms() {
    // Ensure Observables already exist
    await this.getSortedChatRooms$();

    // Re-fetch chat rooms
    const rawChatRooms = await this.getChatRooms(true);
    this.rawChatRooms$.next(rawChatRooms);
  }

  public async getSortedChatRooms$() {
    if (this.sortedChatRooms$) {
      return this.sortedChatRooms$;
    }

    const { id: userId } = await this.userService.getMe();
    const userIdStr = String(userId);

    const rawChatRooms = await this.getChatRooms(true);

    this.rawChatRooms$ = new BehaviorSubject(rawChatRooms);

    const allChatRooms$ = this.rawChatRooms$
      .pipe(
        map(({ data }) => data),
      );

    // Create Observable with unread messages
    const chatStates: { [key: string]: Observable<ChatState> } = {};

    this.sortedChatRooms$ = combineLatest([allChatRooms$, ...Object.values(chatStates)])
      .pipe(
        map(([rooms]) => {
          const chatRooms = rooms as ChatRoom[];

          for (const chatRoom of chatRooms) {
            // Only populate new rooms with Firebase state
            if (chatStates[chatRoom.chatUid]) {
              continue;
            }

            chatStates[chatRoom.chatUid] = this.chatService
              .getChatInfo(chatRoom.chatUid)
              .pipe(
                map((chat) => ({
                  unreadMessages: chat?.unreadMessages?.[userIdStr] ?? 0,
                  lastActivity: chat?.lastActivity?.toMillis() ?? 0,
                })),
              );
          }

          return chatRooms;
        }),
        switchMap(async (chatRooms) => {
          // Extract the current state values out of Observable array
          const staticStates = {} as { [key: string]: ChatState };

          for (const key of Object.keys(chatStates)) {
            staticStates[key] = await chatStates[key].pipe(take(1)).toPromise();
          }

          // Sort all chat rooms by last activity
          return [
            ...chatRooms,
          ]
            .sort((a, b) => {
              const stateA = staticStates[a.chatUid];
              const stateB = staticStates[b.chatUid];

              return (stateB?.lastActivity ?? 0) - (stateA?.lastActivity ?? 0);
            });
        }),
      );

    this.chatStates = chatStates;

    return this.sortedChatRooms$;
  }

  public async getChatStates() {
    // Ensure Observables already exist
    if (!this.sortedChatRooms$) {
      await this.getSortedChatRooms$();
    }

    return this.chatStates;
  }

}
