// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `swiper, swiper-container {
  height: 100%;
  width: 100%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.flex-direction-column {
  flex-direction: column;
}

.overlay {
  position: absolute;
  background: white;
  z-index: 15000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note {
  border-radius: 100%;
  background: var(--ion-color-light-shade);
}

.overflow-y-auto {
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/media-carousel-modal/media-carousel-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,wCAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["swiper, swiper-container {\n  height: 100%;\n  width: 100%;\n}\n\n.image {\n  max-width: 100%;\n  max-height: 100%;\n  height: auto;\n}\n\n.flex-direction-column {\n  flex-direction: column;\n}\n\n.overlay {\n  position: absolute;\n  background: white;\n  z-index: 15000;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.note {\n  border-radius: 100%;\n  background: var(--ion-color-light-shade);\n}\n\n.overflow-y-auto {\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
