import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CoreModule } from '@core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { environment } from '@env/environment';
import { PipesModule } from '@shared';
import { RedirectComponent } from '@app/redirect.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '@app/auth/authentication.service';
import { ApiModule, Configuration } from '@app/finapi';


registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, MenuComponent, RedirectComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true
    }),
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    PipesModule,
    PdfViewerModule,
    ApiModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: APP_INITIALIZER, multi: true, useFactory: (authService: AuthenticationService) => {
        return () => {
          return authService.loadAndStoreToken();
        };
      }, deps: [AuthenticationService],
    },
    {
      provide: Configuration,
      useFactory: (authService: AuthenticationService) => new Configuration(
        {
          basePath: environment.finapiUrl,
          withCredentials: true,
          credentials: {
            finapi_auth: () => {
              authService.loadAndStoreToken().then();
              return authService.getFinApiTokenSync()
            }
          },
        }
      ),
      deps: [AuthenticationService],
      multi: false
    },
    PreviewAnyFile,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
