// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-title {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.mw-50 {
  min-width: 50%;
}

IonBadge, ion-badge, .ion-badge {
  border-radius: 9999px;
  --padding-top: .5em;
  --padding-bottom: .5em;
  --padding-start: 1em;
  --padding-end: 1em;
  min-width: max(50%, 125px);
  background: rgba(var(--ion-color-base-rgb), 0.75);
  color: var(--ion-color-contrast);
}

.ion-card, ion-card {
  flex-grow: 1;
}

:host {
  height: -moz-fit-content;
  height: fit-content;
}

.position-absolute {
  position: absolute;
  width: calc(100% - 2em);
  top: 0;
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.flex-card {
  display: flex;
  align-items: center;
}
.flex-card ion-button {
  margin-left: auto;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}
.flex-card ion-title {
  padding-inline-start: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/problem-card/problem-card.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,qBAAA;EAEA,mBAAA;EACA,sBAAA;EAEA,oBAAA;EACA,kBAAA;EACA,0BAAA;EAEA,iDAAA;EACA,gCAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,wBAAA;EAAA,mBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,uBAAA;EACA,MAAA;EACA,YAAA;AAFF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE,aAAA;EAEA,mBAAA;AAHF;AAKE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAHJ;AAME;EACE,uBAAA;AAJJ","sourcesContent":["ion-card-title {\n  font-weight: bold;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.mw-50 {\n  min-width: 50%;\n}\n\nIonBadge, ion-badge, .ion-badge {\n  border-radius: 9999px;\n\n  --padding-top: .5em;\n  --padding-bottom: .5em;\n\n  --padding-start: 1em;\n  --padding-end: 1em;\n  min-width: max(50%, 125px);\n\n  background: rgba(var(--ion-color-base-rgb), 0.75);\n  color: var(--ion-color-contrast);\n}\n\n.ion-card, ion-card {\n  flex-grow: 1;\n}\n\n:host {\n  height: fit-content;\n}\n\n.position-absolute {\n  position: absolute;\n  width: calc(100% - 2em);\n  top: 0;\n  height: 100%;\n}\n\n.ml-auto {\n  margin-left: auto;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.flex-card {\n  display: flex;\n\n  align-items: center;\n\n  ion-button {\n    margin-left: auto;\n    --padding-top: 0;\n    --padding-bottom: 0;\n    --padding-start: 0;\n    --padding-end: 0;\n  }\n\n  ion-title {\n    padding-inline-start: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
