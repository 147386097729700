import { Component, ViewChild } from '@angular/core';
import { ApportionmentFormula, CostType } from '@app/@core/models/interfaces/cost-type.interface';

import { IonSelect, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Property } from '@core/models/interfaces/property.interface';

const transPath = 'pages.manager-page.finance.revenues.expense.cost-types.';

@Component({
  selector: 'app-cost-modal',
  templateUrl: 'choose-formula-modal.component.html',
  styleUrls: ['./choose-formula-modal.component.scss'],

})
export class ChooseFormulaModalComponent {
  @ViewChild('selectRef') selectRef: IonSelect;
  property: Property;
  costType: CostType;
  apportionmentFormula: ApportionmentFormula;
  lastApportionmentFormulaSelectOption: ApportionmentFormula;
  radioButtonValue: string;

  translations = this.translateService.instant([
    transPath + 'surface',
    transPath + 'persons',
    transPath + 'unit',
    transPath + 'water',
  ]);

  formulas = [
    { label: this.translations[transPath + 'surface'], value: 'living_space' },
    { label: this.translations[transPath + 'persons'], value: 'people' },
    { label: this.translations[transPath + 'unit'], value: 'units' },
    { label: this.translations[transPath + 'water'], value: 'water' },
  ];

  allocationWarningTranslations = this.translateService.instant([
    'pages.manager-page.finance.revenues.expense.modal.allocation-warning-living_space',
    'pages.manager-page.finance.revenues.expense.modal.allocation-warning-people',
    'pages.manager-page.finance.revenues.expense.modal.allocation-warning-units',
  ]);
  allocationWarningTranslation: string;

  constructor(private modalCtrl: ModalController, private navParams: NavParams, private translateService: TranslateService) {
    this.property = this.navParams.get('data').property;
    this.costType = this.navParams.get('data').costType;
    this.apportionmentFormula =
      this.lastApportionmentFormulaSelectOption =
        'living_space';
    this.allocationWarningTranslation = this.allocationWarningTranslations['pages.manager-page.finance.revenues.expense.modal.allocation-warning-living_space'];
    this.radioButtonValue = 'multi-option';
  }


  setFormula(formula: ApportionmentFormula) {
    this.apportionmentFormula = formula;
    this.radioButtonValue = 'direct';
  }

  selectChanged(event: any) {
    this.apportionmentFormula = this.lastApportionmentFormulaSelectOption = event.detail.value;
    this.radioButtonValue = 'multi-option';
    switch (this.apportionmentFormula) {
      case 'living_space':
        this.allocationWarningTranslation = this.allocationWarningTranslations['pages.manager-page.finance.revenues.expense.modal.allocation-warning-living_space'];
        break;
      case 'people':
        this.allocationWarningTranslation = this.allocationWarningTranslations['pages.manager-page.finance.revenues.expense.modal.allocation-warning-people'];
        break;
      case 'units':
        this.allocationWarningTranslation = this.allocationWarningTranslations['pages.manager-page.finance.revenues.expense.modal.allocation-warning-units'];
        break;
      case 'water':
        this.allocationWarningTranslation = '';
        break;
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.apportionmentFormula, 'confirm');
  }

  setFormulaMultiOption() {
    this.radioButtonValue = 'multi-option';
    this.apportionmentFormula = this.lastApportionmentFormulaSelectOption;
  }
}
