import { Component, ElementRef, Input, OnInit, output, ViewChild } from '@angular/core';
import { Unit } from '@core/models/interfaces/unit.interface';
import { AlertController, AnimationController, IonRouterOutlet, ModalController, NavController, ToastController } from '@ionic/angular';
import { UnitRelationshipService } from '@core/api/unit/unit-relationship.service';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { addYears, format, parseISO, subYears } from 'date-fns';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isDateValidator } from '@core/validators/is-date.validator';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { OnlyRent } from '@core/models/datatypes/unit-relationship/only-rent.interface';
import { FixedRateRent } from '@core/models/datatypes/unit-relationship/fixed-rate-rent.interface';
import { CompoundRent } from '@core/models/datatypes/unit-relationship/compound-rent.interface';
import { MatStepper } from '@angular/material/stepper';
import { RelationshipRentMaskComponent } from '@shared/components/relationship-rent-mask/relationship-rent-mask.component';
import { environment } from '@env/environment';
import { nanoid } from 'nanoid';

const animationTime = 300;

@Component({
  selector: 'app-relationship-mask',
  templateUrl: './relationship-mask.component.html',
  styleUrls: ['./relationship-mask.component.scss'],
})
export class RelationshipMaskComponent extends UsesCacheComponent implements OnInit {
  public static MODAL_ID = 'relationship-mask-component';
  @Input() unit: Unit;
  @Input() routerOutlet: IonRouterOutlet;
  @ViewChild('overviewContainer') overViewContainer: ElementRef<HTMLDivElement>;
  @ViewChild('editContainer') editContainer: ElementRef<HTMLDivElement>;
  @ViewChild('stepper') private stepper: MatStepper;

  public editorHasEntered = output<void>();
  public editorHasQuit = output<void>();

  public minimumStartDate: string;
  public maximumStartDate: string;
  public minimumEndDate: string;
  public maximumEndDate: string;
  public renterDetailsForm: UntypedFormGroup;
  public paymentForm: UntypedFormGroup;
  public pagination: Pagination;
  public showOverview = true;
  public showEdit = false;
  public displayedColumns = ['id', 'start', 'end', 'numberOfPersons', 'rentee', 'proprietor'];
  public displayedPaymentColumns = ['start', 'rent', 'note'];
  public displayedPaymentColumnsFixedRate = ['start', 'rent', 'fixedRate', 'note'];
  public displayedPaymentColumnsOperatingCostsHeatingCosts = ['start', 'rent', 'operatingCosts', 'heatingCosts', 'note'];
  public loading = true;
  private internalRelationships: UnitRelationship[] = [];
  private relationshipToEdit: UnitRelationship;
  private hasChangedData = false;
  private currentPage = 1;
  private pageSize = 25;
  public paymentType: string;
  public displayPaymentForm = true;
  public hasChangedDataRentModal = false;
  public rentDetails:any[];
  public rent: (OnlyRent | FixedRateRent | CompoundRent)[] | undefined;

  constructor(
    private readonly modalController: ModalController,
    private readonly unitRelationshipService: UnitRelationshipService,
    private readonly animationController: AnimationController,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastController: ToastController,
    private readonly alertController: AlertController,
    private readonly navController: NavController,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
    this.resetDates();
  }

  get relationships(): UnitRelationship[] {
    return this.internalRelationships;
  }

  get isEditing(): boolean {
    return !!this.relationshipToEdit;
  }

  onCacheCleared(lastRefresh: Date): void | Promise<void> {
    return this.loadRelationships();
  }

  public async ngOnInit() {
    await this.loadRelationships();
    this.isInitialized = true;
  }

  async editRelationship(relation?: UnitRelationship) {
    this.relationshipToEdit = relation;
    const firstname = relation?.rentee?.firstname ?? relation?.proprietor?.firstname;
    const lastname = relation?.rentee?.lastname ?? relation?.proprietor?.lastname;
    const email = relation?.rentee?.email ?? relation?.proprietor?.email;
    const companyName = relation?.rentee?.companyName ?? relation?.proprietor?.companyName;

    this.renterDetailsForm = this.formBuilder.group({
      start: [relation?.startDate ?? '', Validators.compose([Validators.required, isDateValidator])],
      hasEnd: [relation?.endDate != null],
      isProprietor: [relation?.proprietor != null],
      isCompany: [!!companyName],
      end: [relation?.endDate ?? '', isDateValidator],
      firstname: [{ value: firstname ?? '', disabled: !!relation }, Validators.required],
      lastname: [{ value: lastname ?? '', disabled: !!relation }, Validators.required],
      companyName: [{ value: companyName ?? '', disabled: !!relation }],
      email: [{
        value: email ?? '',
        disabled: !!relation,
      }, Validators.compose([Validators.email, Validators.required, Validators.minLength(6)])],
      numberOfPersons: [relation?.numberOfPersons ?? 0, [Validators.required, Validators.min(0)]],
      notes: [relation?.notes ?? ''],
    }, {
      validators: Validators.compose([ifHasEndThanEndIsRequired, startBeforeEnd, ifIsCompanyRequireCompanyName]),
    });

    if (relation?.startDate) {
      this.minimumEndDate = format(parseISO(relation?.startDate), 'yyyy-MM-dd');
    }
    if (relation?.endDate) {
      this.maximumStartDate = format(parseISO(relation?.endDate), 'yyyy-MM-dd');
    }

    // Animation
    await this.transitionFromOverviewToEdit();
  }

  async saveRelation($event: any) {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'errors.header.components.relationship-mask.save-relation-invitation-error-header',
      'errors.header.components.relationship-mask.send-relation-invitation-error-header',
      'errors.header.error-header',
      'errors.message.components.relationship-mask.save-relation-invitation-error-message',
      'errors.message.components.relationship-mask.send-relation-invitation-error-message',
      'components.relationship-mask.send-relation-invitation-message'
    ]));
    $event.preventDefault();
    $event.stopPropagation();
    if (this.renterDetailsForm.invalid || this.renterDetailsForm.disabled) {
      return;
    }

    this.renterDetailsForm.disable();

    if (this.displayPaymentForm){
      if (this.paymentForm.invalid || this.paymentForm.disabled) {
        return;
      }
      this.paymentForm.disable();
    }

    //prepare renterDetailsForm data to send to backend
    try {
      const {
        start,
        hasEnd,
        end,
        firstname,
        lastname,
        isCompany,
        companyName,
        email,
        isProprietor,
        numberOfPersons,
        notes,
      } = this.renterDetailsForm.value;

      let data: any = {
        start,
        end: hasEnd ? end : null,
        isProprietor,
        notes,
        numberOfPersons,
      };

      if (isCompany) {
        data = {
          ...data,
          user: {
            firstname,
            lastname,
            email,
            companyName,
          },
        };
      } else {
        data = {
          ...data, user: {
            firstname,
            lastname,
            email,
          },
        };
      }

      if (this.displayPaymentForm){
        //payment form data 2nd step
        const {
          flatRateRent,
          baseRent,
          totalAdvancePayment,
          operatingCost,
          heatingCost,
          hasFlatRateRent,
          hasTotalAdvancePayment,
          hasOperatingCostsHeatingCosts,
          notesPayment,
          startDatePayment,
        } = this.paymentForm.value;

        let rentPayment: any = {
          startDate: startDatePayment,
          note: notesPayment,
        };

        if (hasFlatRateRent) {
          rentPayment = {
            ...rentPayment,
            rent: flatRateRent,
            __component: 'relationships.only-rent',
          };
        }
        else if(hasTotalAdvancePayment) {
          rentPayment = {
            ...rentPayment,
            rent: baseRent,
            fixedRate: totalAdvancePayment,
            __component: 'relationships.fixed-rate-rent',
          };
        }
        else if(hasOperatingCostsHeatingCosts) {
          rentPayment = {
            ...rentPayment,
            rent: baseRent,
            operatingCosts: operatingCost,
            heatingCosts: heatingCost,
            __component: 'relationships.compound-rent',
          };
        }

        data = {
          ...data,
          'rent':[
            rentPayment
          ],
        };
      }
      else {
        // get the rent update from rent modal
        data = {
          ...data,
          'rent':this.rent,
        };
      }

      if (this.relationshipToEdit) {
        await this.unitRelationshipService.updateRelationship(this.relationshipToEdit.id, data);
      } else {
        const response = await this.unitRelationshipService.createRelationship(this.unit.id, data);
        if (!response.userInvitable) {
          const uninvitableAlert = await this.toastController.create({
            header: translations['errors.header.components.relationship-mask.save-relation-invitation-error-header'],
            message: translations['errors.message.components.relationship-mask.save-relation-invitation-error-message'],
            color: 'danger',
            buttons: [translations['buttons.okay']],
            duration: 5000,
          });
          await uninvitableAlert.present();

          this.renterDetailsForm.enable();
          this.paymentForm.enable();
          return;
        }

        if (response.invitationEmailSuccessful === false) {
          const emailSent = await this.toastController.create({
            header: translations['errors.header.components.relationship-mask.send-relation-invitation-error-header'],
            message: translations['errors.message.components.relationship-mask.send-relation-invitation-error-message'],
            color: 'warning',
            buttons: [translations['buttons.okay']],
            duration: 3000,
          });
          await emailSent.present();
          this.renterDetailsForm.enable();
          this.paymentForm.enable();
          return;
        } else {
          const success = await this.toastController.create({
            header: translations['components.relationship-mask.send-relation-invitation-header'],
            message: translations['components.relationship-mask.send-relation-invitation-message'],
            color: 'success',
            buttons: [translations['buttons.okay']],
            duration: 3000,
          });
          await success.present();
        }
      }

      this.hasChangedData = true;

      await this.clearCacheAndWait();
      await this.loadRelationships();

      await this.resetEdit();
    } catch (e) {
      const errorToast = await this.toastController.create({
        header: translations['errors.header.error-header'],
        message: this.translateService.instant('errors.message.error-message', { error: e }),
        color: 'danger',
        buttons: [translations['buttons.okay']],
        duration: 5000,
      });
      await errorToast.present();

    } finally {
      this.renterDetailsForm?.enable();
      this.paymentForm?.enable();
    }
  }

  public async resetEdit() {
    await this.transitionFromEditToOverview();
    this.relationshipToEdit = undefined;
    this.renterDetailsForm?.enable();
    this.renterDetailsForm?.reset();
    this.renterDetailsForm?.clearValidators();
    this.renterDetailsForm = undefined;
    this.paymentForm?.enable();
    this.paymentForm?.reset();
    this.paymentForm?.clearValidators();
    this.paymentForm = undefined;
    this.paymentType = undefined;
    this.resetStepper();
    this.resetDates();
    this.displayPaymentForm = true;
    this.hasChangedDataRentModal = false;
    this.editorHasQuit.emit();
  }

  /**
   * Reset mat-stepper form
   */
  resetStepper(){
    this.stepper.reset();
  }

  public updateStartDate(value: string | string[]) {
    this.renterDetailsForm?.patchValue({ start: value });
    this.minimumEndDate = format(parseISO(value as string), 'yyyy-MM-dd');
  }
  public updateStartDatePaymentForm(value: string | string[]) {
    this.paymentForm?.patchValue({ startDatePayment: value });
    this.minimumEndDate = format(parseISO(value as string), 'yyyy-MM-dd');
  }

  public updateEndDate(value: string | string[]) {
    this.renterDetailsForm?.patchValue({ end: value });
    this.maximumStartDate = format(parseISO(value as string), 'yyyy-MM-dd');
  }

  public patchHasEnd(event: any) {
    this.renterDetailsForm?.patchValue({ hasEnd: event.checked });
  }

  public patchIsProprietor($event: any) {
    this.renterDetailsForm?.patchValue({ isProprietor: $event.checked });
  }

  public patchIsCompany($event: any) {
    this.renterDetailsForm?.patchValue({ isCompany: $event.checked });
  }

  public async delete() {
    if (!this.relationshipToEdit) {
      return;
    }
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.cancel',
      'buttons.okay',
      'pages.delete-confirm-header',
      'pages.delete-fault-header',
      'pages.delete-fault-message',
      'components.relationship-mask.delete-relation-header'
    ]));

    const alert = await this.alertController.create({
      header: translations['components.relationship-mask.delete-relation-header'],
      message: translations['pages.delete-confirm-header'],
      buttons: [
        {
          role: 'cancel',
          text: translations['buttons.cancel'],
        },
        {
          role: 'delete',
          text: translations['buttons.delete'],
        },
      ],
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    if (result.role !== 'delete') {
      return;
    }

    await this.unitRelationshipService.deleteRelationship(this.relationshipToEdit.id);

    const success = await this.toastController.create({
      header: translations['pages.delete-fault-header'],
      message: translations['pages.delete-fault-message'],
      color: 'success',
      buttons: [translations['buttons.okay']],
      duration: 3000,
    });
    await success.present();

    this.hasChangedData = true;

    await this.clearCacheAndWait();
    await this.loadRelationships();

    await this.resetEdit();
  }

  async goToNextPage() {
    if (!this.pagination) {
      return;
    }

    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }
    this.currentPage++;
    await this.loadRelationships();
  }

  async goToPreviousPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage--;
    await this.loadRelationships();
  }

  async changeAmountOfItems($event: number) {
    if (!this.pagination) {
      return;
    }
    if ($event === this.pagination.pageSize) {
      return;
    }

    this.currentPage = 1;
    this.pageSize = $event;
    await this.loadRelationships();
  }

  async goToLastPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }

    this.currentPage = this.pagination.pageCount;
    await this.loadRelationships();
  }

  async goToFirstPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage = 1;
    await this.loadRelationships();
  }

  public async navigateToUser($event: MouseEvent, id) {
    $event.stopPropagation();
    await this.navController.navigateForward(['', 'app', 'users', id]);
  }

  private async transitionFromEditToOverview() {
    const animation = this.animationController.create()
      .addElement(this.editContainer.nativeElement)
      .duration(animationTime)
      .fromTo('transform', 'translateX(0)', 'translateX(+100%)')
      .easing('ease-in-out');
    animation.play().then();
    this.showOverview = true;

    const animation2 = this.animationController.create()
      .addElement(this.overViewContainer.nativeElement)
      .duration(animationTime)
      .fromTo('transform', 'translateX(-100%)', 'translateX(0)')
      .beforeAddClass('position-absolute')
      .afterRemoveClass('position-absolute')
      .easing('ease-in-out');
    await animation2.play();
    this.showEdit = false;
  }

  private async transitionFromOverviewToEdit() {
    const animation = this.animationController.create()
      .addElement(this.overViewContainer.nativeElement)
      .duration(animationTime)
      .fromTo('transform', 'translateX(0)', 'translateX(-100%)')
      .beforeAddClass('position-absolute')
      .afterRemoveClass('position-absolute')
      .easing('ease-in-out');
    animation.play().then();
    this.showEdit = true;

    const animation2 = this.animationController.create()
      .addElement(this.editContainer.nativeElement)
      .duration(animationTime)
      .fromTo('transform', 'translateX(+100%)', 'translateX(0)')
      .easing('ease-in-out');
    await animation2.play();
    this.showOverview = false;
  }

  private async loadRelationships() {
    try {
      this.loading = true;
      const result = await this.unitRelationshipService.getAllRelationshipsByUnitId(
        this.unit.id,
        this.currentPage,
        this.pageSize,
      );
      this.internalRelationships = result.data;
      this.pagination = result.meta;
    } catch (e) {
      // TODO err
      this.internalRelationships = [];
    }
    this.loading = false;
  }

  private async loadRelationship(){

    try {
      this.loading = true;
      const result = await this.unitRelationshipService.getRelationshipByRelationshipId( this.relationshipToEdit.id);
      this.rent = this.sortRentDueToDate(result.data.rent);
    } catch (e) {
      // TODO err
      console.error("e", e);
    }
    this.loading = false;
  }

  private resetDates() {
    this.minimumStartDate = format(subYears(new Date(), 100), 'yyyy-MM-dd');
    this.maximumStartDate = format(addYears(new Date(), 100), 'yyyy-MM-dd');
    this.minimumEndDate = format(subYears(new Date(), 100), 'yyyy-MM-dd');
    this.maximumEndDate = format(addYears(new Date(), 100), 'yyyy-MM-dd');
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }

  paymentHandleChange($event: any){
    this.paymentType = $event.value;

    switch($event.value){
      case "FlatRateRent":
        this.paymentForm?.patchValue({ hasFlatRateRent: true });
        this.paymentForm?.patchValue({ hasBasicRent: false });
        this.paymentForm?.patchValue({ hasTotalAdvancePayment: false });
        this.paymentForm?.patchValue({ hasOperatingCostsHeatingCosts: false });
        break;
      case "BasicRentTotalAdvancePayment":
        this.paymentForm?.patchValue({ hasFlatRateRent: false });
        this.paymentForm?.patchValue({ hasBasicRent: true });
        this.paymentForm?.patchValue({ hasTotalAdvancePayment: true });
        this.paymentForm?.patchValue({ hasOperatingCostsHeatingCosts: false });
        break;
      case "BasicRentOperatingCostsHeatingCosts":
        this.paymentForm?.patchValue({ hasFlatRateRent: false });
        this.paymentForm?.patchValue({ hasBasicRent: true });
        this.paymentForm?.patchValue({ hasTotalAdvancePayment: false });
        this.paymentForm?.patchValue({ hasOperatingCostsHeatingCosts: true });
        break;
    }
  }

  async editPaymentForm(){
    if (this.relationshipToEdit && Object.prototype.hasOwnProperty.call(this.relationshipToEdit, 'rent')) {
      // when relationship.rent has value, then do not display PaymentForm
      this.displayPaymentForm = this.relationshipToEdit.rent.length <= 0;
      this.rent = this.sortRentDueToDate(this.relationshipToEdit?.rent);
      for (const type of this.rent) {
        switch (type.__component){
          case 'relationships.only-rent':
            this.paymentType = 'FlatRateRent';
            break;
          case 'relationships.fixed-rate-rent':
            this.paymentType = 'BasicRentTotalAdvancePayment';
            break;
          case 'relationships.compound-rent':
            this.paymentType = 'BasicRentOperatingCostsHeatingCosts';
            break;
        }
      }
    }

    //Display PaymentForm only by new relationship or the relationship has no rent type
    if (!this.paymentForm && this.displayPaymentForm){
      // paymentForm
      let flatRateRent: string | number = '';
      let baseRent: string | number  = '';
      let totalAdvancePayment: string | number  = '';
      let operatingCost: string | number  = '';
      let heatingCost: string | number  = '';
      let startDate: string | Date  = '';
      let note = '';

      if (this.relationshipToEdit) {

        for (const type of this.rent) {
          switch (type.__component){
            case 'relationships.only-rent':
              flatRateRent = type.rent ?? '';
              this.paymentType = 'FlatRateRent';
              break;
            case 'relationships.fixed-rate-rent':
              baseRent = type.rent ?? '';
              totalAdvancePayment = type.fixedRate ?? '';
              this.paymentType = 'BasicRentTotalAdvancePayment';
              break;
            case 'relationships.compound-rent':
              baseRent = type.rent ?? '';
              operatingCost = type.operatingCosts ?? '';
              heatingCost = type.heatingCosts ?? '';
              this.paymentType = 'BasicRentOperatingCostsHeatingCosts';
              break;
          }

          startDate = type.startDate;
          note = type.note;
        }
      }

      this.paymentForm = this.formBuilder.group({
        flatRateRent: [ flatRateRent ?? '' ],
        baseRent: [ baseRent ?? '' ],
        totalAdvancePayment: [ totalAdvancePayment ?? ''],
        operatingCost: [ operatingCost ?? ''],
        heatingCost: [ heatingCost ?? ''],
        notesPayment: [ note ?? ''],
        startDatePayment: [
          startDate ?? '',
          Validators.compose([Validators.required, isDateValidator])
        ],
        hasFlatRateRent:[this.paymentType === 'FlatRateRent' ?? false],
        hasBasicRent:[(this.paymentType === 'BasicRentTotalAdvancePayment' || this.paymentType === 'BasicRentOperatingCostsHeatingCosts') ?? false],
        hasTotalAdvancePayment:[this.paymentType === 'BasicRentTotalAdvancePayment' ?? false],
        hasOperatingCostsHeatingCosts:[this.paymentType === 'BasicRentOperatingCostsHeatingCosts' ?? false],
      }, {
        validators: Validators.compose([
          ifHasFlatRateRent,
          ifHasBaseRent,
          ifHasTotalAdvancePayment,
          ifHasOperatingCostsHeatingCosts
        ]),
      });

      if (startDate) {
        this.minimumEndDate = format(parseISO(<string> startDate), 'yyyy-MM-dd');
      }
    }
  }

  async editRelationshipRent(relation?: UnitRelationship) {
    const modal = await this.modalController.create({
      component: RelationshipRentMaskComponent,
      id: RelationshipRentMaskComponent.MODAL_ID,
      cssClass: 'w-75-modal',
      canDismiss: true,
      backdropDismiss: false,
      componentProps: {
        rent: this.rent,
        unit: this.unit,
        relationshipId: this.relationshipToEdit.id
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    this.hasChangedDataRentModal = data.hasChangedData;

    await this.loadRelationship();
  }

  public sortRentDueToDate(rentArray: any[]): any[] {
    rentArray.sort((a, b) => {
      const startD1 = new Date(a.startDate);
      const startD2 = new Date(b.startDate);
      return startD1.getTime() - startD2.getTime();
    });
    return rentArray;
  }

  generateEmail() {
    this.renterDetailsForm.get('email').patchValue(`${environment.emailPrefix}${encodeURIComponent(nanoid(16))}${environment.emailSuffix}`);
    this.renterDetailsForm.get('email').markAsTouched();
  }
}

const ifHasFlatRateRent: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }
  const hasFlatRateRent = control.get('hasFlatRateRent');
  const flatRateRent = control.get('flatRateRent');

  if (hasFlatRateRent.value === true && !flatRateRent.hasValidator(Validators.required)) {
    flatRateRent.addValidators(Validators.required);
    flatRateRent.updateValueAndValidity();
    return flatRateRent.errors;
  } else if (hasFlatRateRent.value === false && flatRateRent.hasValidator(Validators.required)) {
    flatRateRent.removeValidators(Validators.required);
    flatRateRent.updateValueAndValidity();
  }
  return null;
};

const ifHasBaseRent: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }

  const hasBasicRent = control.get('hasBasicRent');
  const baseRent = control.get('baseRent');
  if (
    hasBasicRent.value === true && !baseRent.hasValidator(Validators.required)
  ) {
    baseRent.addValidators(Validators.required);
    baseRent.updateValueAndValidity();
    return baseRent.errors;
  } else if (
    hasBasicRent.value === false && baseRent.hasValidator(Validators.required)
  ) {
    baseRent.removeValidators(Validators.required);
    baseRent.updateValueAndValidity();
  }
  return null;
};

const ifHasTotalAdvancePayment: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }

  const hasTotalAdvancePayment = control.get('hasTotalAdvancePayment');
  const totalAdvancePayment = control.get('totalAdvancePayment');
  if (hasTotalAdvancePayment.value === true && !totalAdvancePayment.hasValidator(Validators.required)) {
    totalAdvancePayment.addValidators(Validators.required);
    totalAdvancePayment.updateValueAndValidity();
    return totalAdvancePayment.errors;
  } else if (hasTotalAdvancePayment.value === false && totalAdvancePayment.hasValidator(Validators.required)) {
    totalAdvancePayment.removeValidators(Validators.required);
    totalAdvancePayment.updateValueAndValidity();
  }
  return null;
};

const ifHasOperatingCostsHeatingCosts: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }

  const hasOperatingCostsHeatingCosts = control.get('hasOperatingCostsHeatingCosts');
  const operatingCost = control.get('operatingCost');
  const heatingCost = control.get('heatingCost');
  if (
    hasOperatingCostsHeatingCosts.value === true &&
    (!operatingCost.hasValidator(Validators.required) && !heatingCost.hasValidator(Validators.required))
  ) {
    operatingCost.addValidators(Validators.required);
    operatingCost.updateValueAndValidity();
    heatingCost.addValidators(Validators.required);
    heatingCost.updateValueAndValidity();
    return [operatingCost.errors, heatingCost.errors];
  } else if (hasOperatingCostsHeatingCosts.value === false &&
    operatingCost.hasValidator(Validators.required)
  ) {
    operatingCost.removeValidators(Validators.required);
    operatingCost.updateValueAndValidity();
    heatingCost.removeValidators(Validators.required);
    heatingCost.updateValueAndValidity();
  }
  return null;

};

const ifHasEndThanEndIsRequired: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }
  const hasEnd = control.get('hasEnd');
  const end = control.get('end');

  if (hasEnd.value === true && !end.hasValidator(Validators.required)) {
    end.addValidators(Validators.required);
    end.updateValueAndValidity();
    return end.errors;
  } else if (hasEnd.value === false && end.hasValidator(Validators.required)) {
    end.removeValidators(Validators.required);
    end.updateValueAndValidity();
  }
  return null;
};

const ifIsCompanyRequireCompanyName: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }
  const isCompany = control.get('isCompany');
  const companyName = control.get('companyName');

  if (isCompany.value === true && !companyName.hasValidator(Validators.required)) {
    companyName.addValidators(Validators.required);
    companyName.updateValueAndValidity();
    return companyName.errors;
  } else if (isCompany.value === false && companyName.hasValidator(Validators.required)) {
    companyName.removeValidators(Validators.required);
    companyName.updateValueAndValidity();
  }
  return null;
};

const startBeforeEnd: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }
  const hasEnd = control.get('hasEnd');
  const end = control.get('end');
  const start = control.get('end');
  if (!hasEnd.value) {
    return null;
  }

  const startDate = parseISO(start.value);
  const endDate = parseISO(end.value);

  if (startDate > endDate) {
    return { startBeforeEnd: 'Start ist nach Ende' };
  }
  return null;
};
