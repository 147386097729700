// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail ion-img::part(image), ion-avatar ion-img::part(image) {
  background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));
}

.avatar {
  --size: 64px;
  --border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/user-card/user-card.component.scss"],"names":[],"mappings":"AACE;EACE,wGAAA;AAAJ;;AAIA;EACE,YAAA;EACA,qBAAA;AADF","sourcesContent":["ion-thumbnail, ion-avatar {\n  ion-img::part(image) {\n    background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));\n  }\n}\n\n.avatar {\n  --size: 64px;\n  --border-radius: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
