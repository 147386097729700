import { Component, computed, input, Input, OnInit, output } from '@angular/core';
import { Account, BankConnection } from '@app/finapi';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent {
  account = input.required< Account>();
  bank = input< BankConnection>();

  transactions = output<void>();
  delete = output<void>();

  accountNumberStart = computed(() => {
    if(this.account().iban) {
     return  this.account().iban.slice(0, 4);
    } else {
      return  this.account().accountNumber.slice(0, 4);
    }
  })
  accountNumberEnd = computed(() => {
    if(this.account().iban) {
      return  this.account().iban.slice(this.account().iban.length -4, this.account().iban.length);
    } else {
      return  this.account().accountNumber.slice(this.account().accountNumber.length -4, this.account().accountNumber.length);
    }
  })
}
