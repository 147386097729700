import { User } from '@core/models/interfaces/user.interface';
import DOMPurify from 'dompurify';
const sanitize = DOMPurify.sanitize;

export const getName = (user: User | undefined | null, includeBreaks = false) => {
  if (!user) {
    return '';
  }
  if (!!user.companyName && user.companyName.trim().length > 0) {
    return sanitize(`${user.companyName}${includeBreaks ? '<br>' : ' ('}${user.firstname} ${user.lastname}${!includeBreaks ? ')' : ''}`);
  }
  return sanitize(`${user.firstname} ${user.lastname}`);
};
