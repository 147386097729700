import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
const sanitize = DOMPurify.sanitize;

@Pipe({
  name: 'sanitize',
  pure: true,
})

export class SanitizePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(sanitize(
      value,
    ));

  }
}
