import { Component, OnDestroy, OnInit } from '@angular/core';

import { Logger } from '@core/logger/logger.service';
import { StorageService } from '@core/storage/storage.service';
import { AuthenticationService } from './auth/authentication.service';
import { PushNotificationService } from '@core/push-notification/push-notification.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { CacheService } from '@core/api/cache/cache.service';
import { LanguageService } from '@core/i18n/language.service';

const log = new Logger('a.AppComponent');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private updateFinapiToken: any;

  constructor(
    private readonly storageService: StorageService,
    private readonly authService: AuthenticationService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly cacheService: CacheService,
  ) {
    this.subscription = router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        if (!router.navigated) {
          await cacheService.clearCache();
        }
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('Initializing app...');

    await this.storageService.init();
    await this.authService.validateLogin();
    await this.languageService.loadTranslations();

    // Check if notification permissions are allowed. Does not prompt the user.
    this.pushNotificationService.checkNotifications();

    this.updateFinapiToken = setInterval(() => {
      this.authService.loadAndStoreToken()
    }, 5 * 60 * 1000)
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
    clearInterval(this.updateFinapiToken);
  }

}
