// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'ires-app',
    appId: '1:294994507091:web:cc814f3ae5a206aca85da6',
    storageBucket: 'ires-app.appspot.com',
    locationId: 'europe-west3',
    apiKey: 'AIzaSyB7y70ibZISl9IQmuYavX7uZT4gLN5t0Cg',
    authDomain: 'ires-app.firebaseapp.com',
    messagingSenderId: '294994507091',
  },
  production: false,
  defaultLanguage: 'de-DE',
  supportedLanguages: ['de-DE', 'en-US'],
  finapiUrl: 'https://sandbox.finapi.io',
};

export const apiSettings = {
  baseUrl: 'https://ires-api.amicaldo.net',
  cacheExpirationTime: 5 * 60 * 1000, // 5 Minutes
};
