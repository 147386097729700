// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-chip {
  --background: #000;
  --color: #FFF;
}

.icon {
  font-size: 3em;
}
.icon.icon-sm {
  font-size: 1.2em;
}
.icon.icon-md {
  font-size: 1.5em;
}

.fixed-table {
  table-layout: fixed;
  overflow-x: auto;
}

.wrapper {
  max-width: 100dvw;
  overflow-x: auto;
}

.pointer {
  cursor: pointer;
}

.file-row td {
  padding-bottom: 1em;
}
.file-row td.checkbox {
  padding-right: 1em;
}

.actions-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.actions-list .left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/document-list/document-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,mBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAIE;EACE,mBAAA;AADJ;AAGI;EACE,kBAAA;AADN;;AAMA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAHF;AAIE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAFJ","sourcesContent":["ion-chip {\n  --background: #000;\n  --color: #FFF;\n}\n\n.icon {\n  font-size: 3em;\n  &.icon-sm {\n    font-size: 1.2em;\n  }\n\n  &.icon-md {\n    font-size: 1.5em;\n  }\n}\n\n.fixed-table {\n  table-layout: fixed;\n  overflow-x: auto;\n}\n\n.wrapper {\n  max-width: 100dvw;\n  overflow-x: auto;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.file-row {\n  td {\n    padding-bottom: 1em;\n\n    &.checkbox {\n      padding-right: 1em;\n    }\n  }\n}\n\n.actions-list {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: wrap;\n  .left {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n  }\n\n  .right {\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
