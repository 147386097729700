import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
const sanitize = DOMPurify.sanitize;
import { marked } from 'marked';
import { apiSettings } from '@env/environment';

@Pipe({
  name: 'markdown',
  pure: true,
})

export class MarkdownPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: string, breaks = false, inline = false): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(sanitize(
      inline
        ? marked.parseInline(value, { breaks, gfm: true , baseUrl: apiSettings.baseUrl})
        : marked.parse(value, { breaks, gfm: true, baseUrl: apiSettings.baseUrl }),
      inline ? {
        FORBID_TAGS: [
          'a', 'img', 'script', 'style', 'link', 'video', 'audio'
        ]
      } : undefined
      ));
  }
}
