export * from './accounts.service';
import { AccountsService } from './accounts.service';
export * from './authorization.service';
import { AuthorizationService } from './authorization.service';
export * from './bankConnections.service';
import { BankConnectionsService } from './bankConnections.service';
export * from './banks.service';
import { BanksService } from './banks.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './clientConfiguration.service';
import { ClientConfigurationService } from './clientConfiguration.service';
export * from './labels.service';
import { LabelsService } from './labels.service';
export * from './mandatorAdministration.service';
import { MandatorAdministrationService } from './mandatorAdministration.service';
export * from './mocksAndTests.service';
import { MocksAndTestsService } from './mocksAndTests.service';
export * from './notificationRules.service';
import { NotificationRulesService } from './notificationRules.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './pendingTransactions.service';
import { PendingTransactionsService } from './pendingTransactions.service';
export * from './securities.service';
import { SecuritiesService } from './securities.service';
export * from './standingOrders.service';
import { StandingOrdersService } from './standingOrders.service';
export * from './tPPCertificates.service';
import { TPPCertificatesService } from './tPPCertificates.service';
export * from './tPPCredentials.service';
import { TPPCredentialsService } from './tPPCredentials.service';
export * from './transactions.service';
import { TransactionsService } from './transactions.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AccountsService, AuthorizationService, BankConnectionsService, BanksService, CategoriesService, ClientConfigurationService, LabelsService, MandatorAdministrationService, MocksAndTestsService, NotificationRulesService, PaymentsService, PendingTransactionsService, SecuritiesService, StandingOrdersService, TPPCertificatesService, TPPCredentialsService, TransactionsService, UsersService];
